import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Dropdown, ButtonGroup} from 'react-bootstrap';

import { ProgressBar } from 'react-bootstrap';
export class Countrydet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            main: "",
            allpost:"",
            na:"",
            isLoaderct: false,
            conterid:"",
            pre:"",
            isChecked:false,
            cer:"",
            name:"",
            global: true,
            catergory1:"Выберите категория",
            
  conterid1:"Выберите страну",
            test:"",
            img:"",
            base:"",
            id:"",
            check:false,
            cash:"",
            format:"",
cate:"",
conte:"",
            img2:"",
            base2:"",
            isLoader: false,
            format2:"",
            selectedFile: null
        };
        this.onFileChange = this.onFileChange.bind(this);
        this.onFileChanges = this.onFileChanges.bind(this);  
        this.enabs = this.enabs.bind(this);  
        
    }
   
    enabs = e =>{
console.log(e.target.checked)
      this.setState({
        global: e.target.checked
      })
    }


  componentDidMount(){
   

    fetch('https://mwapi.proc.one/api/content/get_all_categories', {
      method: 'GET',


      headers: {

          // 'Content-Type': 'application/json',
          'auth': "9f87855f-8671-4868-a55f-7fa21c2d0b58",
          // 'token': localStorage.getItem('auth')
      },
  })
      .then(res => res.json())

      .then((data) => {
  console.log(data)
          if (data) {
           
              if (data.all_categories) {
                  if (data.all_categories.length == "0") {

                  } else {
                      this.setState({
                          isLoader: true,
                          cate: data.all_categories,
                      
                      })
               
                  }
              } else {

              }
          }


      }
      )



      fetch('https://mwapi.proc.one/api/content/get_all_countries', {
        method: 'GET',
  
  
        headers: {
  
            // 'Content-Type': 'application/json',
            'auth': "114baae6-6e54b-49c7-8rvd5d-8efd572*c17a4e",
             'token': localStorage.getItem('auth')
        },
    })
        .then(res => res.json())
  
        .then((data) => {
    console.log(data)
            if (data) {
             
                if (data.countries_list) {
                    if (data.countries_list.length == "0") {
  
                    } else {
                        this.setState({
                            isLoaderct: true,
                            conte: data.countries_list,
                        
                        })
                 
                    }
                } else {
  
                }
            }
  
  
        }
        )


      const url = this.props.match.params.id

      const obj = JSON.parse(localStorage.getItem('arras'));

      for(var i = 0; i < obj.length; i++)
      if(obj[i].id == url){
        console.log(obj[i])
        if(obj[i].country_img_url == ""){
        
        }else{
        }
     
        this.setState({
            main:obj[i],
            id:obj[i].id,
            isLoad: true,
            name: obj[i].text,
            img: obj[i].country_img_url,
            img2: "",
            check: obj[i].enable,
            cash: obj[i].available_cashback
        })
      }

   
  }

  name = e =>{
      this.setState({
          name: e.target.value
      })
  }

  enab = e =>{
      console.log(e.target.checked)
      if(e.target.checked == false){
        this.setState({
            check: false,
        })
      }else{
          this.setState({
            check: true,
          })
      }
  }


  onFileChange = e => {
    
    // Update the state
    const data = new FormData();
    const imagedata = e.target.files[0];
    console.log(e.target.files)
    data.append('myfile', imagedata);
    // console.log(base64)
    if (imagedata.name.indexOf(".")) {

        let { file } = this.state;

        file = e.target.files[0];


        let baseURL = "";
        // Make new FileReader

        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;

            const test = { "type": "chat", "chat_id": 3, "message": 1111 }

            const main = {
                "type": "chat", "message": file.name, "chat_id": sessionStorage.getItem('ms'), "file": {
                    "file_type": imagedata.name.substr(imagedata.name.indexOf(".") + 1),
                    "file": baseURL.split(',')[1]
                }
            }
            this.setState({
                base: main.file.file,
                format: main.file.file_type,
                img: baseURL,
            })
          console.log(main)


            // this.setState({
            //     isLoaded: true
            // })





        };






    }


  };
  onFileChanges = e => {
    
    // Update the state
    const data = new FormData();
    const imagedata = e.target.files[0];
    console.log(e.target.files)
    data.append('myfile', imagedata);
    // console.log(base64)
    if (imagedata.name.indexOf(".")) {

        let { file } = this.state;

        file = e.target.files[0];


        let baseURL = "";
        // Make new FileReader

        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;

 

            const main = {
                "type": "chat", "message": file.name, "chat_id": sessionStorage.getItem('ms'), "file": {
                    "file_type": imagedata.name.substr(imagedata.name.indexOf(".") + 1),
                    "file": baseURL.split(',')[1]
                }
            }
            this.setState({
                base2: main.file.file,
                format2: main.file.file_type,
                img2: baseURL,
            })
          console.log(main)


            // this.setState({
            //     isLoaded: true
            // })





        };






    }


  };

  cash = e =>{
      this.setState({
          cash: e.target.value
      })
  }

 
  save = e =>{
    const urlid = this.props.match.params.id
    e.preventDefault()

const test = {
  "proc_id": urlid,
  "product_name": this.state.name,
  "need_account": true,
  "category_id": this.state.catergory,
  "is_global": this.state.global,
  "country_id": this.state.conterid,
  "available_cashback": 1,
  "is_available": true,
    "file_type":this.state.format2,
    "product_img":this.state.base2
}
console.log(this.state.global)
        fetch('https://mwapi.proc.one/api/admin/add_product', {
            method: 'POST',


            headers: {
                'Content-Type': 'application/json',
                'auth': "114baae6-6e54b-49c7-8rvd5d-8efd572*c17a4e",
                'token': localStorage.getItem('auth')
            },
            body: JSON.stringify({
              "proc_id": urlid,
              "product_name": this.state.name,
              "need_account": true,
              "category_id": this.state.catergory,
              "is_global": this.state.global,
              "country_id": this.state.conterid,
              "available_cashback": 1,
              "is_available": true,
                "file_type":this.state.format2,
                "product_img":this.state.base2

            })

        }
        )

            .then(res => res.json())

            .then((data) => {
                console.log(data)



                if (data.status == true) {
                     window.location.replace("/product");
                }


            }
            )

    console.log(test)
  }
  cater(obj){
    console.log(obj)
    this.setState({
      catergory:obj.main,
      catergory1: obj.name
    })
  }
  cater1(obj){
    console.log(obj)
    this.setState({
      conterid:obj.main,
      conterid1: obj.name
    })
  }
  
    render() {
        const {isLoad, main, name, img, img2, check, cash, isLoader, cate, isLoaderct} = this.state
     if(!isLoad || !isLoader || !isLoaderct){
return(
    <p></p>
)
     }else{
        return(
            <div className="row">
          
           
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">{main.name}</h4>





                    <p>Выберите категорию</p>
                  <Dropdown >
                    <Dropdown.Toggle variant="btn btn-primary" id="dropdownMenuButton1">
                      {this.state.catergory1}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
          
                    {cate.map((item, index) => 
                       (
                        <Dropdown.Item onClick={() => this.cater(
                          {
                            "main": item.id,
                            "name": item.category_name
                          }
                        )}>{item.category_name}</Dropdown.Item>
                       )

                    )}


                    </Dropdown.Menu>
                  </Dropdown>
                  <p>Выберите Страну</p>
                  <br/>
                  <Dropdown>
                    <Dropdown.Toggle variant="btn btn-primary"  id="dropdownMenuButton1">
                    {this.state.conterid1}
                    </Dropdown.Toggle>
                    <Dropdown.Menu  className='menuspisok'>
          
                    {this.state.conte.map((item, index) => 
                       (
                        <Dropdown.Item onClick={() => this.cater1(
                          {
                            "main": item.id,
                            "name": item.name
                          }
                        )}>{item.name}</Dropdown.Item>
                       )

                    )}


                    </Dropdown.Menu>
                  </Dropdown>

                  
                  
                  <form className="forms-sample">
               
             
                 



                    
                  <Form.Group>
                    
                    {
                        img2 == "" &&
                        <p>Нету картинки страны</p>
                    }
                     {
                        img2 != "" &&
                    <div>    <img className='imgicons' src={img2}></img></div>
                    }
                 
                    <div className="custom-file">
                      <Form.Control type="file" onChange={this.onFileChanges} className="form-control visibility-hidden" id="customFileLang2" lang="es"/>
                      <label className="custom-file-label" htmlFor="customFileLang2">Upload image</label>
                    </div>
                  </Form.Group>




                    <Form.Group >
                      <label htmlFor="exampleInputCity1">Кешбэк</label>
                      <Form.Control onChange={this.cash} value={cash} type="text" className="form-control" id="exampleInputCity1" placeholder="available_cashback" />
                    </Form.Group>
                    <Form.Group>
                    
                    <div className="form-check">
                    <label className="form-check-label">
                        <input type="checkbox"   defaultChecked={this.state.global}  onClick={this.enabs} className="form-check-input"/>
                        <i className="input-helper"></i>
                        Глобально
                      </label>
                    </div>
               
                  </Form.Group>
                    <button onClick={this.save} type="submit" className="btn btn-primary mr-2">Сохранить</button>
                    <button className="btn btn-dark">Отмена</button>
                  </form>
                </div>
              </div>
            </div>
          
          </div>
           )
     }
     
       }
    }
export default Countrydet
