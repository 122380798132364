import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { ProgressBar } from 'react-bootstrap';
export class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            main: "",
            allpost:"",
            na:"",
            pre:"",
            isChecked:false,
            cer:"",
            test:""
        };

    }
    search = e => {
        this.setState({
            isChecked:false
        })
       
        const main = this.state.allpost.filter(item =>
            item.text.toLowerCase().includes(e.target.value.toLowerCase())
        );
        console.log(main)
    this.setState({ 
        main,
        na: e.target.value,
        pre: "",
        cer: "",
       
    });
        }


    seaprefix = e => {
        console.log(e.target.value)
        this.setState({
            isChecked:false
        })
		// filter post list by title using onKeyUp function
        const main = this.state.allpost.filter(item =>

            item.id.toString().toLowerCase().includes(e.target.value.toLowerCase())
        );
        console.log(main)
		this.setState({ main,
        pre: e.target.value,
        na: "",
        cer: "",
        isChecked:false
        });
	};

   

    
    


    componentDidMount() {
        fetch('https://mwapi.proc.one/api/admin/get_all_products', {
            method: 'GET',


            headers: {

                // 'Content-Type': 'application/json',
                'auth': "114baae6-6e54b-49c7-8rvd5d-8efd572*c17a4e",
                'token': localStorage.getItem('auth')
            },


        })


            .then(res => res.json())

            .then((data) => {
                if (data) {
                    console.log(data)
                    if (data.products_list) {
                        if (data.products_list.length == "0") {

                        } else {
                            this.setState({
                                isLoad: true,
                                main: data.products_list,
                                allpost: data.products_list,
                            })
                            localStorage.setItem('arras', JSON.stringify(data.products_list))
                        }
                    } else {

                    }
                }


            }
            )
    }

foto = e =>{
    console.log(e.target.checked)

 if(e.target.checked == false){
     
    const main = this.state.allpost.filter(word => word);

    this.setState({ main,
    cer: "",
    na: "",
    pre: "",
    isChecked:false,
    });
 }else{
  
    const main = this.state.allpost.filter(word => word.country_img_url.length == 0);

    this.setState({ main,
    cer: "",
    na: "",
    pre: "",
    isChecked:true,
    });
    
 }
}
    
    render() {
        const { isLoad } = this.state
        if (!isLoad) {
            return (
                <p className='titlecener'>У вас нету стран</p>
            )
        } else {
            return (
                <div>

                    <div className="row">
                        <div className="col-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Поиск</h4>
                                 
                                    <form className="form-inline">
                                        <label className="sr-only" htmlFor="inlineFormInputName2">Name</label>
                                        <Form.Control value={this.state.na} onChange={this.search} type="text" className="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Поиск по стране" />
                                        <label className="sr-only" htmlFor="inlineFormInputGroupUsername2">Username</label>
                                        <div className="input-group mb-2 mr-sm-2">
                                       
                                            <Form.Control value={this.state.pre}  onChange={this.seaprefix} type="text" className="form-contro l" id="inlineFormInputGroupUsername2" placeholder="Поиск по Id" />
                                        </div>
                                  
                                    
                                    </form>
                                    <div className="col-md-2">
                        
                                        
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Страны</h4>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th> Id </th>
                                                    <th> Название продукта </th>

                                                  
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {this.state.main.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                         
                                                            <td> {item.id} </td>

                                                            <td>{item.text} </td>
                                                            
                                                            <td>  <Link type="button"
                                                        
                                                        to={'/productdet/'+ item.id}
                                                         className="btn btn-outline-secondary btn-icon-text">
                                                             Edit
                                                             <i className="mdi mdi-file-check btn-icon-append"></i>
                                                         </Link></td>
                                                        </tr>
                                                    )
                                                })}





                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )
        }

    }
}

export default Product
