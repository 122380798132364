import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value1: '',
      value2: '',
      errortext: '',
    };

    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange1(event) {
    this.setState({ value1: event.target.value });
  }
  handleChange2(event) {
    this.setState({ value2: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault()
    if (this.state.value1 == "admin@proc.one" && this.state.value2 == "sapphire1933") {
      localStorage.setItem('auth', '114baae6-6e54b-49c7-8rvd5d-8efd572*c17a4e')
      window.location.reload()
    
    } else {
      this.setState({
        errortext: "Логин или пароль не правильный"
      })
    
    }
  
  }


  render() {
    return (
      <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0">
            <div className="col-lg-4 mx-auto">
              <div className="card text-left py-5 px-4 px-sm-5">
                <div className="brand-logo">
                </div>
                <h4>Админ панель</h4>
                <Form onSubmit={this.handleSubmit} className="pt-3">
            
                  {
                    this.state.errortext != "" &&
                    <p className='err'>{this.state.errortext}</p>
                  }
                  <Form.Group className="d-flex search-field">

                    <Form.Control onChange={this.handleChange1} type="email" placeholder="Username" value={this.state.value1} size="lg" className="h-auto" />
                  </Form.Group>
                  <Form.Group onChange={this.handleChange2} className="d-flex search-field">
                    <Form.Control type="password" placeholder="Password" value={this.state.value} size="lg" className="h-auto" />
                  </Form.Group>
                  {
                    this.state.value1 == "" || this.state.value2 &&
                    <div className="mt-3">
                    <input className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type="submit" value="Авторизация" />

                  </div>
                  }
                

                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
