import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { ProgressBar } from 'react-bootstrap';
export class Country extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            main: "",
            allpost:"",
            na:"",
            pre:"",
            isChecked:false,
            cer:"",
            test:""
        };

        this.edit = this.edit.bind(this);
        this.search = this.search.bind(this);
        this.seaprefix = this.seaprefix.bind(this);
        this.seacurre = this.seacurre.bind(this);
        this.foto = this.foto.bind(this);
  
    }
    edit = obj =>{

        console.log(obj.id)
        localStorage.setItem('id', JSON.stringify(obj.id))
        window.location.pathname = '/te'
    }
    search = e => {
        this.setState({
            isChecked:false
        })
        console.log(e.target.value.toLowerCase)
        const main = this.state.allpost.filter(item =>
            item.shortName.toLowerCase().includes(e.target.value.toLowerCase())
        );
    this.setState({ 
        main,
        na: e.target.value,
        pre: "",
        cer: "",
       
    });
        }


    seaprefix = e => {
        this.setState({
            isChecked:false
        })
		// filter post list by title using onKeyUp function
		const main = this.state.allpost.filter(item =>
			item.phonePrefix.toLowerCase().includes(e.target.value.toLowerCase())
		);
		this.setState({ main,
        pre: e.target.value,
        na: "",
        cer: "",
        isChecked:false
        });
	};

    seacurre = e => {
        this.setState({
            isChecked:false
        })
		// filter post list by title using onKeyUp function
		const main = this.state.allpost.filter(item =>
			item.currency.toLowerCase().includes(e.target.value.toLowerCase())
		);
		this.setState({ main,
        cer: e.target.value,
        na: "",
        pre: "",
        isChecked:false
        });
	};

    
    


    componentDidMount() {
        fetch('https://mwapi.proc.one/api/admin/get_available_processing_countries', {
            method: 'GET',


            headers: {

                'Content-Type': 'application/json',
                'auth': "114baae6-6e54b-49c7-8rvd5d-8efd572*c17a4e",
                'token': localStorage.getItem('auth')
            },


        })


            .then(res => res.json())

            .then((data) => {
                if (data) {
                    console.log(data)
                    if (data.error == "0") {
                        if (data.countries_list.length == "0") {

                        } else {
                            this.setState({
                                isLoad: true,
                                main: data.countries_list,
                                allpost: data.countries_list,
                            })
                            localStorage.setItem('arra', JSON.stringify(data.countries_list))
                        }
                    } else {

                    }
                }


            }
            )
    }

foto = e =>{
    console.log(e.target.checked)

 if(e.target.checked == false){
     
    const main = this.state.allpost.filter(word => word);

    this.setState({ main,
    cer: "",
    na: "",
    pre: "",
    isChecked:false,
    });
 }else{
  
    const main = this.state.allpost.filter(word => word.country_img_url.length == 0);

    this.setState({ main,
    cer: "",
    na: "",
    pre: "",
    isChecked:true,
    });
    
 }
}
    
    render() {
        const { isLoad } = this.state
        if (!isLoad) {
            return (
                <p className='titlecener'>У вас нету стран</p>
            )
        } else {
            return (
                <div>

                    <div className="row">
                        <div className="col-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Поиск</h4>
                                 
                                    <form className="form-inline">
                                        <label className="sr-only" htmlFor="inlineFormInputName2">Name</label>
                                        <Form.Control value={this.state.na} onChange={this.search} type="text" className="form-control mb-2 mr-sm-2" id="inlineFormInputName2" placeholder="Поиск по стране" />
                                        <label className="sr-only" htmlFor="inlineFormInputGroupUsername2">Username</label>
                                        <div className="input-group mb-2 mr-sm-2">
                                       
                                            <Form.Control value={this.state.pre}  onChange={this.seaprefix} type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="Поиск по префиксу" />
                                        </div>
                                  
                                        <div className="input-group mb-2 mr-sm-2">
                                       
                                       <Form.Control value={this.state.cer}  onChange={this.seacurre} type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="Поиск по валюте" />
                                       <div className="form-check">
                      
                        </div>
                                   </div>
                                    </form>
                                    <div className="col-md-2">
                                    <Form.Group>
                    
                    <div className="form-check">
                    <label className="form-check-label">
                        <input type="checkbox"   defaultChecked={this.state.isChecked}  onClick={this.foto} className="form-check-input"/>
                        <i className="input-helper"></i>
                        Страны без иконки
                      </label>
                    </div>
               
                  </Form.Group>
                                        
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Страны</h4>
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th> Иконка </th>
                                                    <th> Страна </th>

                                                    <th> Префикс </th>
                                                    <th> Валюта </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {this.state.main.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="py-1">
                                                                <img src={item.country_img_url} />
                                                            </td>
                                                            <td> {item.shortName} </td>

                                                            <td>{item.phonePrefix} </td>
                                                            <td> {item.currency} </td>
                                                            <td>  <Link type="button"
                                                        
                                                           to={'/countrydet/'+ item.id}
                                                            className="btn btn-outline-secondary btn-icon-text">
                                                                Edit
                                                                <i className="mdi mdi-file-check btn-icon-append"></i>
                                                            </Link></td>
                                                        </tr>
                                                    )
                                                })}





                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )
        }

    }
}

export default Country
