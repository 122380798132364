import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import Login55  from './user-pages/Login';
import Country  from './Page/Country';
import Product  from './Page/Product';

import Countrydet  from './Page/Countrydet';
import Productdet  from './Page/Productdet';


const Dashboard = lazy(() => import('./dashboard/Dashboard'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
const Typography = lazy(() => import('./basic-ui/Typography'));

const BasicElements = lazy(() => import('./form-elements/BasicElements'));

const BasicTable = lazy(() => import('./tables/BasicTable'));

const Mdi = lazy(() => import('./icons/Mdi'));

const ChartJs = lazy(() => import('./charts/ChartJs'));






const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Register1 = lazy(() => import('./user-pages/Register'));


class AppRoutes extends Component {
  render () {

    
      if(localStorage.getItem('auth') == null || localStorage.getItem('auth') == undefined || localStorage.getItem('auth') == ""){
        return (
          <Suspense fallback={<Spinner/>}>
            <Switch>
              <Route path="/user-pages/login-1" component={ Login55 } />

              <Redirect to="/user-pages/login-1" />
            </Switch>
          </Suspense>
        );
      }else{
        return (
          <Suspense fallback={<Spinner/>}>
            <Switch>
              <Route exact path="/dashboard" component={ Dashboard } />
    
              <Route exact path="/country" component={ Country } />
              <Route exact path="/product" component={ Product } />
              <Route exact path="/countrydet/:id" component={ Countrydet } />
              <Route exact path="/productdet/:id" component={ Productdet } />
              {/* <Route path="/charts/chart-js" component={ ChartJs } /> */}
              {/* <Route path="/basic-ui/buttons" component={ Buttons } /> */}
              {/* <Route path="/basic-ui/dropdowns" component={ Dropdowns } /> */}
              {/* <Route path="/basic-ui/typography" component={ Typography } /> */}
    
              {/* <Route path="/form-Elements/basic-elements" component={ BasicElements } /> */}
    
              {/* <Route path="/tables/basic-table" component={ BasicTable } /> */}
    
              {/* <Route path="/icons/mdi" component={ Mdi } /> */}
    
              {/* <Route path="/charts/chart-js" component={ ChartJs } /> */}
    
              
              <Route path="/login-1" component={ Login } />
              {/* <Route path="/user-pages/register-1" component={ Register1 } /> */}
    
              {/* <Route path="/error-pages/error-404" component={ Error404 } /> */}
              {/* <Route path="/error-pages/error-500" component={ Error500 } /> */}
    
    
              <Redirect to="/dashboard" />
            </Switch>
          </Suspense>
        );
      }


   
  }
}

export default AppRoutes;