import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { Form } from 'react-bootstrap';
import 'react-notifications/lib/notifications.css';
import { ProgressBar } from 'react-bootstrap';
export class Countrydet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoad: false,
            main: "",
            allpost:"",
            na:"",
            pre:"",
            isChecked:false,
            cer:"",
            name:"",
            test:"",
            img:"",
            base:"",
            id:"",
            check:false,
            base5:"",
            cash:"",
            base1:"",
            oper:"",
            format1:"",
            img5: "",
            format5:"",
            format:"",
            img1: "",
            img3: "",
            img2:"",
            base2:"",
            format2:"",
            selectedFile: null
        };
        this.onFileChange = this.onFileChange.bind(this);
        this.onFileChange1 = this.onFileChange1.bind(this);
        this.onFileChanges = this.onFileChanges.bind(this);  
    }
   
    

 oper = e =>{
   this.setState({
     oper:e.target.value
   })
 }
 addoper = e =>{
  e.preventDefault()
  const test = {
    "available": true, 
    "file_type": this.state.format5,
    "operator_name": this.state.oper,
    "operator_img": this.state.base5,

  }
console.log(test)
      fetch('https://mwapi.proc.one/api/admin/add_operator_img', {
          method: 'POST',


          headers: {
              'Content-Type': 'application/json',
              'auth': "114baae6-6e54b-49c7-8rvd5d-8efd572*c17a4e",
              'token': localStorage.getItem('auth')
          },
          body: JSON.stringify({
              "available":true, 
              "file_type":this.state.format5,
              "operator_name": this.state.oper,
              "operator_img":this.state.base5,

          })

      }
      )

          .then(res => res.json())

          .then((data) => {
              console.log(data)



              if (data) {
                // window.location.replace("/country");
                NotificationManager.success('Success message', 'Title here');
              }


          }
          )

  console.log(test)
 }
  componentDidMount(){
      const url = this.props.match.params.id

      const obj = JSON.parse(localStorage.getItem('arra'));

      for(var i = 0; i < obj.length; i++)
      if(obj[i].id == url){
        console.log(obj[i])
        if(obj[i].country_img_url == ""){
        
        }else{
        }
     
        this.setState({
            main:obj[i],
            id:obj[i].id,
            isLoad: true,
            name: obj[i].name,
            img: obj[i].country_img_url,
            img2: "",
            img3: obj[i].country_city_img,
            img1: obj[i].country_city_img,
            check: obj[i].enable,
            cash: obj[i].available_cashback
        })
      }

   
  }

  name = e =>{
      this.setState({
          name: e.target.value
      })
  }

  enab = e =>{
      console.log(e.target.checked)
      if(e.target.checked == false){
        this.setState({
            check: false,
        })
      }else{
          this.setState({
            check: true,
          })
      }
  }


  onFileChange = e => {
    
    // Update the state
    const data = new FormData();
    const imagedata = e.target.files[0];
    console.log(e.target.files)
    data.append('myfile', imagedata);
    // console.log(base64)
    if (imagedata.name.indexOf(".")) {

        let { file } = this.state;

        file = e.target.files[0];


        let baseURL = "";
        // Make new FileReader

        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;

            const test = { "type": "chat", "chat_id": 3, "message": 1111 }

            const main = {
                "type": "chat", "message": file.name, "chat_id": sessionStorage.getItem('ms'), "file": {
                    "file_type": imagedata.name.substr(imagedata.name.indexOf(".") + 1),
                    "file": baseURL.split(',')[1]
                }
            }
            this.setState({
                base: main.file.file,
                format: main.file.file_type,
                img: baseURL,
            })
          console.log(main)


            // this.setState({
            //     isLoaded: true
            // })





        };






    }


  };

  

  onFileChange2 = e => {
    
    // Update the state
    const data = new FormData();
    const imagedata = e.target.files[0];
    console.log(e.target.files)
    data.append('myfile', imagedata);
    // console.log(base64)
    if (imagedata.name.indexOf(".")) {

        let { file } = this.state;

        file = e.target.files[0];


        let baseURL = "";
        // Make new FileReader

        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;

            

            const main = {
                "type": "chat", "message": file.name, "chat_id": sessionStorage.getItem('ms'), "file": {
                    "file_type": imagedata.name.substr(imagedata.name.indexOf(".") + 1),
                    "file": baseURL.split(',')[1]
                }
            }
            this.setState({
                base5: main.file.file,
                format5: main.file.file_type,
                img5: baseURL,
            })
          console.log(main)


        };






    }


  };

  onFileChange1 = e => {
    
    // Update the state
    const data = new FormData();
    const imagedata = e.target.files[0];
    console.log(e.target.files)
    data.append('myfile', imagedata);
    // console.log(base64)
    if (imagedata.name.indexOf(".")) {

        let { file } = this.state;

        file = e.target.files[0];


        let baseURL = "";
        // Make new FileReader

        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;

            

            const main = {
                "type": "chat", "message": file.name, "chat_id": sessionStorage.getItem('ms'), "file": {
                    "file_type": imagedata.name.substr(imagedata.name.indexOf(".") + 1),
                    "file": baseURL.split(',')[1]
                }
            }
            this.setState({
                base1: main.file.file,
                format1: main.file.file_type,
                img1: baseURL,
            })
          console.log(main)


        };






    }


  };


  onFileChanges = e => {
    
    // Update the state
    const data = new FormData();
    const imagedata = e.target.files[0];
    console.log(e.target.files)
    data.append('myfile', imagedata);
    // console.log(base64)
    if (imagedata.name.indexOf(".")) {

        let { file } = this.state;

        file = e.target.files[0];


        let baseURL = "";
        // Make new FileReader

        let reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            console.log("Called", reader);
            baseURL = reader.result;

 

            const main = {
                "type": "chat", "message": file.name, "chat_id": sessionStorage.getItem('ms'), "file": {
                    "file_type": imagedata.name.substr(imagedata.name.indexOf(".") + 1),
                    "file": baseURL.split(',')[1]
                }
            }
            this.setState({
                base2: main.file.file,
                format2: main.file.file_type,
                img2: baseURL,
            })
          console.log(main)


            // this.setState({
            //     isLoaded: true
            // })





        };






    }


  };

  cash = e =>{
      this.setState({
          cash: e.target.value
      })
  }
  save = e =>{
    e.preventDefault()
    const test = {
      "available_cashback":this.state.cash, 
                "enable":this.state.check,
                "proc_cntry_id": this.state.id,
                "file_type":this.state.format,
                "country_img":this.state.base,
                "country_city_img": this.state.base1,
                "country_city_img_file_type": this.state.format1
    }
console.log(test)
        fetch('https://mwapi.proc.one/api/admin/country/add', {
            method: 'POST',


            headers: {
                'Content-Type': 'application/json',
                'auth': "114baae6-6e54b-49c7-8rvd5d-8efd572*c17a4e",
                'token': localStorage.getItem('auth')
            },
            body: JSON.stringify({
                "available_cashback":this.state.cash, 
                "enable": this.state.check,
                "proc_cntry_id": this.state.id,
                "file_type":this.state.format,
                "country_img":this.state.base,
                "country_city_img": this.state.base1,
                "country_city_img_file_type": this.state.format1
            })

        }
        )

            .then(res => res.json())

            .then((data) => {
                console.log(data)



                if (data) {
                   window.location.replace("/country");
                }


            }
            )

    console.log(test)
  }
    render() {
        const {isLoad, main, name, img, img2, check, cash, img1, img3, img5, oper} = this.state
     if(!isLoad){
return(
    <p></p>
)
     }else{
        return(
            <div className="row">
            <NotificationContainer/>
           
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">{main.name}</h4>
   <h1>Добавить иконку оператора</h1>
                  <Form.Group>
                   
                   {
                       img5 == "" &&
                       <p>Нету картинки оператора</p>
                   }
                    {
                       img5 != "" &&
                   <div>    <img className='imgicons' src={img5}></img></div>
                   }
                
                   <div className="custom-file">
                     <Form.Control type="file" onChange={this.onFileChange2} className="form-control visibility-hidden" id="customFileLang5" lang="es"/>
                     <label className="custom-file-label" htmlFor="customFileLang5">Закгрузить иконку оператора</label>
                   </div>
                 </Form.Group>

                 <Form.Group>
                      <label htmlFor="exampleInputCity1">Название оператора</label>
                      <Form.Control onChange={this.oper} value={oper} type="text" className="form-control" id="exampleInputCity1" placeholder="Название Оператора" />
                    </Form.Group>

                   {
                     this.state.oper.length > 0 && this.state.base5.length >0 &&(
<button onClick={this.addoper} type="submit" className="btn btn-primary mr-2">Добавить иконку оператора</button>
                     )
                   }
                    




                  <form className="forms-sample">
                    <Form.Group className='gabarest'>
                      <label htmlFor="exampleInputName1">{main.name}</label>
                      <Form.Control  disabled type="text" onChange={this.name} className="form-control disabtext" value={name} id="exampleInputName1" placeholder={main.name} />
                    </Form.Group>
             
             
                    <Form.Group>
                   
                      {
                          img == "" &&
                          <p>Нету иконки</p>
                      }
                       {
                          img != "" &&
                      <div className='myimagegdp'>    <img className='imgicons' src={img}></img></div>
                      }
                   
                      <div className="custom-file mycontacts">
                        <Form.Control type="file" onChange={this.onFileChange} className="form-control visibility-hidden" id="customFileLang" lang="es"/>
                        <label className="custom-file-label" htmlFor="customFileLang">Upload image</label>
                      </div>
                    </Form.Group>






                    <Form.Group className='gabarest'>
                   
                      {
                          img1 == "" &&
                          <p>Нету картинки страны</p>
                      }
                       {
                          img1 != "" &&
                      <div className='myimagegdp'>    <img className='imgicons' src={img1}></img></div>
                      }
                   
                        <div className="custom-file mycontacts">
                        <Form.Control type="file" onChange={this.onFileChange1} className="form-control visibility-hidden" id="customFileLang1" lang="es"/>
                        <label className="custom-file-label" htmlFor="customFileLang1">Upload image</label>
                      </div>
                    </Form.Group>


                    <Form.Group className='widtthgg'>
                    
                    <div className="form-check">
                    <label className="form-check-label">
                        <input type="checkbox"   defaultChecked={this.state.check}  onClick={this.enab} className="form-check-input"/>
                        <i className="input-helper"></i>
                        Включен
                      </label>
                    </div>
               
                  </Form.Group>


           




                    <Form.Group className='widtthgg'>
                      <label htmlFor="exampleInputCity1">Кешбэк</label>
                      <Form.Control onChange={this.cash} value={cash} type="text" className="form-control" id="exampleInputCity1" placeholder="available_cashback" />
                    </Form.Group>
         
                    <button onClick={this.save} type="submit" className="btn btn-primary mr-2">Сохранить</button>
                    <button className="btn btn-dark">Cancel</button>
                  </form>
                </div>
              </div>
            </div>
          
          </div>
           )
     }
     
       }
    }
export default Countrydet
